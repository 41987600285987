import awsLogo from '../assets/aws_marketplace-horizontal.png';
import azureLogo from '../assets/azure-horizontal.png';
import gcpLogo from '../assets/gcp-horizontal.png';
import redhatLogo from '../assets/redhat-horizontal.png';


export const AWS = 'aws';
export const GCP = 'gcp';
export const AZURE = 'azure';
export const REDHAT = 'redhat';

export const CLOUDS = {
  AWS,
  GCP,
  AZURE,
  REDHAT,
};

export const CLOUD_LOGOS = {
  [AWS]: awsLogo,
  [GCP]: gcpLogo,
  [AZURE]: azureLogo,
  [REDHAT]: redhatLogo,
};

export const CONTACT_TACKLE = 'Contact Tackle';
export const CONTACT_SUPPORT = 'mailto:support@tackle.io';

export const VALIDATION_TYPE_EMAIL = 'email';

export default {
  CLOUDS,
  CLOUD_LOGOS
};
