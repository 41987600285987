import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  return {
    logoContainer: ({ isSmall }) => ({
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(12),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(12),
      maxHeight: theme.typography.pxToRem(isSmall ? 150 : 200),
    }),
    logo: ({ isSmall }) => ({
      maxHeight: theme.typography.pxToRem(isSmall ? 100 : 125),
      maxWidth: theme.typography.pxToRem(isSmall ? 200 : 300),
    })
  };
});

export default useStyles;
