import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import classNames from '@sindresorhus/class-names';

// ASSETS
import useStyles from './Button.styles';

const WrappedButton = ({
  className,
  brand,
  palette,
  appearance = 'primary',
  variant = 'contained',
  fullWidth = false,
  loading = false,
  children = null,
  startIcon = null,
  ...props
}) => {
  const classes = useStyles({ brand, palette });

  // STYLES
  const buttonClasses = {
    root: classNames(classes.root, className, {
      noChildren: !children,
    }),
    [variant]: classNames(classes[variant], {
      secondary: appearance === 'secondary',
      primary: appearance === 'primary',
      destructive: appearance === 'destructive',
      success: appearance === 'success',
      white: appearance === 'white',
    }),
    startIcon: classNames(classes.startIcon, {
      noChildren: !children,
    }),
    disabled: classNames(classes.disabled),
  };

  const spinnerClasses = {
    root: classNames(classes.circularProgress, variant, {
      secondary: appearance === 'secondary',
      primary: appearance === 'primary',
      destructive: appearance === 'destructive',
      success: appearance === 'success',
      white: appearance === 'white',
    }),
  };

  const cssLoadingSpinner = classNames(classes.loadingSpinner, {
    asIcon: startIcon,
  });

  const cssButtonText = classNames(classes.buttonText, {
    loading,
    withIcon: startIcon,
  });

  // RENDERS
  const loadingSpinner = (
    <span className={cssLoadingSpinner}>
      <CircularProgress
        size={20}
        classes={spinnerClasses}
      />
    </span>
  );

  const iconLeft = (loading && startIcon) ? loadingSpinner : startIcon;

  return (
    <Button
      disableRipple
      variant={variant}
      classes={buttonClasses}
      fullWidth={fullWidth}
      startIcon={iconLeft}
      {...props}
    >
      <span className={cssButtonText}>{children}</span>
      {(loading && !startIcon) && loadingSpinner}
    </Button>
  );
};

WrappedButton.propTypes = {
  /** The Button component has a appearance property with 5 available values: */
  appearance: PropTypes.oneOf(['primary', 'secondary', 'destructive', 'success', 'white']),
  /** The variant to use. */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  /** Element placed before the children. */
  startIcon: PropTypes.element,
  /** If true, the button will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** If true, the button will be disabled. */
  disabled: PropTypes.bool,
  /** A handler that is called when the user clicks in the button */
  onClick: PropTypes.func,
};

export default WrappedButton;
