import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  alertContainer: {
    zIndex: 5,
  },
  alert: {
    backgroundColor: theme.palette.NEUTRAL500,
    boxSizing: 'border-box',
    boxShadow: theme.depth.d500,
    color: theme.palette.NEUTRAL000,
    width: '100%',
    zIndex: '600',
    borderRadius: 3,
    padding: theme.spacing(1, 2),
    transition: 'background-color 200ms ease 0s',
    minHeight: 68,
    display: 'flex',
    alignItems: 'center',
  },
  noShadow: {
    boxShadow: 'none',
  },
  alertInfo: {
    backgroundColor: theme.palette.BLUE400,
  },
  alertSuccess: {
    backgroundColor: theme.palette.GREEN400,
  },
  alertDanger: {
    backgroundColor: theme.palette.RED400,
  },
  alertWarning: {
    color: theme.palette.NEUTRAL700,
    backgroundColor: theme.palette.YELLOW200,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  buttonContainer: {
    paddingLeft: theme.spacing(10),
    color: theme.palette.BLUE400,
  },
  button: {
    backgroundColor: theme.palette.NEUTRAL000,
    color: theme.palette.BLUE400,
    '&:hover': {
      backgroundColor: theme.palette.NEUTRAL020
    }
  },
  buttonText: {
    color: theme.palette.BLUE400,
  }
}));

export default useStyles;
