import React, { useContext } from 'react';
import RegistrationContext from '../../../contexts/RegistrationContext';
import TitleMessage from '../../../components/TitleMessage';

const RegistrationHeader = () => {

  const {
    state: {
      product,
    },
  } = useContext(RegistrationContext);

  return (
    <TitleMessage
      title={'One more step...'}
      message={
        <>
          <>{'Thank you for your purchase of '}</>
          <strong>{product.name}</strong>
          <>{'. Complete your registration now to activate your subscription. The purchase is not complete until this form has been submitted.'}</>
        </>
      }
    />
  );
};

export default RegistrationHeader;
