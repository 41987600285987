import api from '../utils/http';

export const getProduct = (vendorid, productid) => {
  return api.get(`vendor/${vendorid}/product/${productid}`).json();
};

export const getSessionDetails = (sessionId) => {
  return api.get(`v1/registration/${sessionId}`).json();
};

export const register = (
  vendorid,
  productid,
  customerid,
  orderProps
) => {
  return api.post(
    `vendor/${vendorid}/product/${productid}/order/${customerid}`,
    { json: orderProps }
  ).json();
};

export default {
  getProduct,
  register
};
