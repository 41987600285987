import {AZURE} from './constants';

const AZURE_DEFAULT_DISPLAY_NAME = 'Default Directory';

const FullName = (firstName, lastName) => {
  const seperator = ' ';

  if (firstName && lastName) {
    return firstName + seperator + lastName;
  }

  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return '';
};

const FullAddress = (street, city, state, zip, country) => {
  const seperator = ', ';
  let fullAddress = '';

  if (street) {
    fullAddress = fullAddress + street + seperator;
  }
  if (city) {
    fullAddress = fullAddress + city + seperator;
  }
  if (state) {
    fullAddress = fullAddress + state + seperator;
  }
  if (zip) {
    fullAddress = fullAddress + zip + seperator;
  }
  if (country) {
    fullAddress = fullAddress + country;
  }
  fullAddress.trim();
  return fullAddress;
};

const AzurePhone = (orgBusinessPhones, userBusinessPhones, mobilePhone) => {
  if (orgBusinessPhones.length) {
    return orgBusinessPhones[0];
  }
  if (userBusinessPhones.length) {
    return userBusinessPhones[0];
  }
  if (mobilePhone) {
    return mobilePhone;
  }
  return '';
};

const AzureCompany = (displayName) => {
  let companyName = '';
  if (displayName && displayName !== AZURE_DEFAULT_DISPLAY_NAME) {
    companyName = displayName;
  }
  return companyName;
};

export const remapUserToForm = (registrationFormFields, userData) => {
  const findFieldByTitleOrDisplayTitle = (fields = [], searchKey) =>
    fields.find(field => field.title === searchKey || field.display_title === searchKey);

  return Object.entries(userData).reduce((remappedFields, [userDataField, userDataVal]) => {
    const foundField = findFieldByTitleOrDisplayTitle(registrationFormFields, userDataField);
    const fieldTitle = foundField?.title || foundField?.display_title;
    return {
      ...remappedFields,
      [fieldTitle || userDataField]: userDataVal,
    };
  }, {});
};

const AzureUser = ({
  userProfile: {
    givenName = '',
    surname = '',
    userPrincipalName = '',
    businessPhones: userBusinessPhones = [],
    mobilePhone = ''
  },
  orgProfile: {
    value: [
      {
        verifiedDomains = [],
        street = '',
        city = '',
        state = '',
        postalCode = '',
        country = '',
        businessPhones: orgBusinessPhones = [],
        displayName = '',
      } = {},
    ] = [],
  } = {},
}) => ({
  'First Name': givenName,
  'Last Name': surname,
  'Full Name': FullName(givenName, surname),
  'Email Address': userPrincipalName,
  'Company': AzureCompany(displayName),
  'Company Website': verifiedDomains?.filter((d) => d.isDefault)[0]?.name || '',
  'Company Address': FullAddress(street, city, state, postalCode, country),
  'Phone Number': AzurePhone(orgBusinessPhones, userBusinessPhones, mobilePhone),
});

export const RegistrationUser = (registrationFields, userData, cloud) => {
  let user = userData;
  if (cloud === AZURE) {
    user =  AzureUser(userData);
  }
  return remapUserToForm(registrationFields, user);
};
