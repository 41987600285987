import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: ({ background_color }) => ({
    height: '100%',
    backgroundColor: background_color || theme.palette.background.paper,
  }),
  formContainer: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(12)
  },
  inputContainer: {
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
}));

export default useStyles;
