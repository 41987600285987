import React from 'react';
import Button from '../../../../components/Button';
import AzureLogo from '../../../../components/Logos/AzureLogo';


const renderAzureAutofill = (handleAutofill) => {
  const CLOUD_KEY = 'AZURE';

  return (
    <Button
      fullWidth
      appearance='primary'
      variant="outlined"
      startIcon={<AzureLogo width="1em" height="1em" />}
      loading={false}
      onClick={() => handleAutofill(CLOUD_KEY)}
    >
      Autofill with Azure AD
    </Button>
  );
};

export default renderAzureAutofill;
