import React from 'react';

import {
  CircularProgress,
} from '@material-ui/core';

const Spinner = () => {
  return (
    <CircularProgress color="primary" />
  );
};

export default Spinner;
