import React from 'react';

import Theme from '../../../Theme';
import useStyles from './RegistrationError.styles';

import Alert from '../../../components/Alert/Alert';
import Button from '../../../components/Button';
import { Link } from '@material-ui/core';

import { CONTACT_SUPPORT } from '../../../utils/constants';

const RegistrationError = ({product, vendor, alertError}) => {
  const classes = useStyles(Theme);

  const buildEmail = (ctaButton, product, vendor) => {
    if (!ctaButton.href || !ctaButton.subject || !product.cloud || !vendor.name){
      return CONTACT_SUPPORT;
    }
    else {
      const cloud = product?.cloud.toUpperCase() || '';
      return ctaButton.href + '?subject=' + cloud + ' ' + vendor.name + ' ' + ctaButton.subject;
    }
  };

  const renderCtaButton = (buttonInfo) => {
    return (
      <Button className={classes.ctaButton} appearance='white'>
        <Link className={classes.ctaButtonText} underline='none' href={buildEmail(buttonInfo, product, vendor)} external='true'>{buttonInfo.buttonText}</Link>
      </Button>
    );
  };

  const renderAlert = (alertError) => {
    return (
      <Alert
        title={alertError.message}
        appearance={alertError.appearance}
        actions={alertError.ctaButton ? renderCtaButton(alertError.ctaButton) : undefined}
      >
      </Alert>
    );
  };

  return (
    renderAlert(alertError)
  );
};

export default RegistrationError;
