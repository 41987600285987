import React from 'react';
import classNames from '@sindresorhus/class-names';
import uuid4 from 'uuid';
import {
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

// ASSETS
import { useTextFieldStyles, TextInputBase } from './TextField.styles';

const TextField = ({
  error = undefined,
  helperText = undefined,
  type = undefined,
  label = undefined,
  disabled = false,
  id = uuid4(),
  name,
  onFocus = () => {},
  onBlur = () => {},
  onChange = undefined,
  placeholder,
  inputProps = undefined,
  size = 'medium',
  required = false,
  ...props
}) => {
  const handleRef = (el) => {
    if (props.inputRef) {
      if (typeof props.inputRef === 'function') {
        props.inputRef(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        props.inputRef.current = el;
      }
    }
  };

  const showErrorState = Boolean(error);
  const showErrorMessage = error && typeof error === 'string';

  const handleChange = (event) => {
    if (event.nativeEvent) {
      event.persist();
    }
    if (onChange) {
      onChange(event);
    }
  };

  const classes = useTextFieldStyles();
  const cssInputBase = classNames(classes.inputBase, {
    [classes.inputDisabled]: disabled,
    [classes.inputError]: showErrorState,
    [classes.smallSize]: size === 'small',
  });

  return (
    <FormControl fullWidth disabled={disabled} error={showErrorState}>
      {
        <InputLabel classes={{ root: classes.inputLabel }} shrink htmlFor={id}>
          {label}
          <span className={classes.required}>{required && ' *'}</span>
        </InputLabel>
      }
      <div className={classes.inputContainer}>
        <TextInputBase
          type={type}
          id={id}
          className={cssInputBase}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange}
          name={name}
          inputProps={inputProps}
          required={required}
          {...props}
          inputRef={handleRef}
        />
      </div>
      <div className={classes.footerContainer}>
        <div className={classes.helperTextContainer}>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {showErrorMessage && <FormHelperText>{error}</FormHelperText>}
        </div>
      </div>
    </FormControl>
  );
};

export default TextField;
