import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import theme from './Theme';
import Register from './pages/Register';
import UnrecognizedIdentifier from './pages/UnrecognizedIdentifier';
import { RegistrationProvider } from './contexts/RegistrationContext';

const App = ({ initialEntries = [] }) => {
  return (
    <>
      <CssBaseline />
      <BrowserRouter initialEntries={initialEntries}>
        <ThemeProvider theme={theme}>
          <RegistrationProvider>
            <Switch>
              <Route exact path={'/register/:vendorid/:productid/:customerid'} component={Register} />
              <Route exact path={'/preview/:vendorid/:productid'} component={() => <Register preview={true}/>} />
              <Route exact path={'/'} component={UnrecognizedIdentifier} />
              <Redirect to={'/'} />
            </Switch>
          </RegistrationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
