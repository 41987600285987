import React, { useContext } from 'react';
import RegistrationContext from '../../../contexts/RegistrationContext';
import useStyles from './RegistrationFooter.styles';
import { CLOUD_LOGOS } from '../../../utils/constants';
import { Divider } from '@material-ui/core';
import poweredByTackle from '../../../assets/poweredby_tackle.png';

const RegistrationFooter = () => {
  const classes = useStyles();

  const {
    state: {
      product,
    },
  } = useContext(RegistrationContext);

  return (
    <div className={classes.footerContainer}>
      <Divider />

      <div className={classes.poweredByContainer}>
        <img className={classes.poweredBy} alt={'logo_tackle-poweredby'} src={poweredByTackle} />
      </div>

      <div className={classes.copyrightContainer}>
        <div>
          <img
            className={classes.cloudLogo}
            alt={`logo_${product.cloud}`}
            src={CLOUD_LOGOS[product.cloud]}
          />
        </div>

        <div className={classes.textBottom}>
          <a
            href={'https://tackle.io/privacy-policy/'}
            target={'_blank'}
            rel={'noopener noreferrer'}
            className={classes.privacyPolicyLink}
          >
            {'Privacy Policy'}
          </a>
          <span>&nbsp;|&nbsp;</span>
          <span>{  '© 2021 Tackle.io, Inc.' }</span>
        </div>
      </div>
    </div>
  );
};

export default RegistrationFooter;
