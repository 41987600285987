import ky from 'ky';

// import hub, { setUserDetails } from './sentry';
import { httpFriendlyErrors, FriendlyHTTPError } from '../utils/errors';


// TODO refactor for registration (this was taken from the buyer portal)
// async function captureExceptionInSentry(response) {
//   if (response.status >= 400) {
//     const body = await response.json();

//     const {
//       email,
//       nickname,
//     } = profile;

//     setUserDetails(
//       email,
//       nickname,
//       hub,
//       response,
//     );

//     hub.withScope(scope => {
//       Object.keys(body).forEach(key => {
//         scope.setExtra(key, body[key]);
//       });

//       scope.setExtra('statusText', response.statusText);
//       scope.setExtra('status', response.status);
//       scope.setExtra('url', response.url);

//       hub.captureMessage(body.code);
//     });
//   }
// }

async function throwFriendlyError(response) {
  if (response.status >= 400) {
    const errorJson = await response.json() || {};

    if (httpFriendlyErrors[errorJson.code]) {
      throw new FriendlyHTTPError({
        code: errorJson.code,
        message: httpFriendlyErrors[errorJson.code].message,
        ctaButton: httpFriendlyErrors[errorJson.code].ctaButton,
      });
    }
    throw new FriendlyHTTPError(errorJson);
  }
}

const kyApi = ky.extend({
  prefixUrl: process.env.REACT_APP_API_URL,
  timeout: 60000,
  throwHttpErrors: false,
  hooks: {
    afterResponse: [
      throwFriendlyError,
    ],
  }
});

const handler = {
  get(target, prop) {
    return function (input, options) {
      // encodedInput now encodes the path attached to the prefixUrl.
      // Firefox is unable to properly encode the pipe character which cause the
      // pre-flight OPTIONS request to fail due to missing CORS heads that are
      // required to interact with the API.
      // example: /vendor/users/auth0|12345 -> /vendor/users/auth0%7C12345
      const encodedInput = encodeURI(input);
      return target[prop].apply(this, [encodedInput, options]);
    };
  },
};

const api = new Proxy(kyApi, handler);

export default api;
