import React from 'react';
import Alert from '../../../components/Alert/Alert';
import useStyles from './RegistrationSuccess.styles';

import { Box } from '@material-ui/core';

export const DEFAULTS = {
  BODY: 'You’ve purchased an enterprise product that requires some additional setup. A representative from our Customer Success Team will be contacting you within two business days with your account credentials. Please contact Support through our website if you have any questions.',
  ALERT: 'Success! Registration Complete.',
  REDIRECT: 'You will be redirected in the next 15 seconds. If you are not redirected, please copy and paste this url into your browser - '
};

const RegistrationSuccess = ({
  successBodyMessage,
  successAlertMessage,
  redirectMessage,
  redirectUri,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert
        appearance="success"
        title={successAlertMessage || DEFAULTS.ALERT}
      />

      <Box className={classes.body}>
        {successBodyMessage || DEFAULTS.BODY}
      </Box>

      {redirectUri && (
        <Box className={classes.paragraph}>
          {redirectMessage || DEFAULTS.REDIRECT}
          <a href={redirectUri}> {redirectUri} </a>
        </Box>
      )}
    </div>
  );
};

export default RegistrationSuccess;
