import { UserAgentApplication } from 'msal';
import { Client } from '@microsoft/microsoft-graph-client';
import { FriendlyHTTPError, httpFriendlyErrors } from '../utils/errors';

const SCOPES = ['user.read'];


// Note: Most of this was taken from the azure-sso-spa demo project

const getAuthenticatedClient = (accessToken) => {
  // Initialize Graph client
  return Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken);
    }
  });
};

const getUserDetails = (accessToken) => {
  const client = getAuthenticatedClient(accessToken);
  return client.api('/me').get();
};

const userAgentApp = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_ID,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
});

const getOrgDetails = (accessToken) => {
  const client = getAuthenticatedClient(accessToken);
  return client.api('/organization').get();
};

const isInteractionRequired = (error) => {
  if (!error.message || error.message.length <= 0) {
    return false;
  }

  return (
    error.message.indexOf('consent_required') > -1 ||
    error.message.indexOf('interaction_required') > -1 ||
    error.message.indexOf('login_required') > -1
  );
};

const getAccessToken = async () => {
  try {
    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    const { accessToken } = await userAgentApp.acquireTokenSilent({
      scopes: SCOPES
    });
    return accessToken;
  } catch (err) {
    // If a silent request fails, it may be because the user needs
    // to login or grant consent to one or more of the requested scopes
    if (isInteractionRequired(err)) {
      const { accessToken } = await userAgentApp.acquireTokenPopup({
        scopes: SCOPES
      });

      return accessToken;
    } else {
      throw err;
    }
  }
};

const getFullProfile = async () => {
  let profile = {
    userProfile: {},
    orgProfile: {}
  };
  const accessToken = await getAccessToken(SCOPES);
  if (accessToken) {
    const user = await getUserDetails(accessToken);
    profile.userProfile = user;

    const org = await getOrgDetails(accessToken);
    profile.orgProfile = org;

    return profile;
  }
};


export async function login() {
  try {
    await userAgentApp.loginPopup(
      {
        scopes: SCOPES,
        prompt: 'select_account'
      });

    return await getFullProfile();
  }
  catch(err) {
    throw new FriendlyHTTPError({
      code: 'azure_autofill_failure',
      message: httpFriendlyErrors['azure_autofill_failure'].message,
    });
  }
}

export const logout = () => {
  userAgentApp.logout();
};
