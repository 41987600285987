import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
  },
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  content: {
    display: 'block',
    position: 'relative',
    fontSize: '2rem',
    textAlign: 'center',
    color: 'darkgray'
  },
}));

const UnrecognizedIdentifier = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div
          className={classes.content}
        >
          { 'Registration for the selected product is not available at this time.' }
        </div>
      </div>
    </div>
  );
};

export default UnrecognizedIdentifier;
