import { createMuiTheme } from '@material-ui/core/styles';

// COLORS
const TackleLightBlue = '#639a9f';
const TackleBlue = '#002b41';
const TackleYellow = '#ffb600';

const theme = createMuiTheme({
  status: {
    success: '#00875A',
    warning: TackleYellow,
    info: TackleBlue,
    error: '#DE350B',
    onGoing: '#0052CC',
  },
  google: {
    light: '#679df6',
    main: '#4285F4',
    dark: '#2e5daa',
    contrastText: '#fff',
  },
  linkedin: {
    light: '#3392c3',
    main: '#0077B5',
    dark: '#00537e',
    contrastText: '#fff',
  },
  palette: {
    primary: {
      light: TackleLightBlue,
      main: TackleBlue,
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc433',
      main: TackleYellow,
      dark: '#ff9900',
      contrastText: '#000',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      disabled: '#939393',
      hint: '#639A9F',
      success: '#328969',
      dark: '#636363',
    },
    divider: '#D8D8D8',
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
    BLUE050: '#DEEBFF',
    BLUE075: '#B3D4FF',
    BLUE100: '#4C9AFF',
    BLUE200: '#2684FF',
    BLUE300: '#0065FF',
    BLUE400: '#0052CC',
    BLUE500: '#0747A6',
    GREEN050: '#E2FFEE',
    GREEN075: '#ABF5D1',
    GREEN100: '#79F2C0',
    GREEN200: '#57D9A3',
    GREEN300: '#36B37E',
    GREEN400: '#00875A',
    GREEN500: '#006644',
    NEUTRAL000: '#ffffff',
    NEUTRAL010: '#FAFBFC',
    NEUTRAL020: '#F4F5F7',
    NEUTRAL030: '#EBECF0',
    NEUTRAL040: '#DFE1E6',
    NEUTRAL050: '#C1C7D0',
    NEUTRAL060: '#B3BAC5',
    NEUTRAL070: '#A5ADBA',
    NEUTRAL080: '#97A0AF',
    NEUTRAL090: '#8993A4',
    NEUTRAL100: '#7A869A',
    NEUTRAL200: '#6B778C',
    NEUTRAL300: '#5E6C84',
    NEUTRAL400: '#505F79',
    NEUTRAL500: '#42526E',
    NEUTRAL600: '#344563',
    NEUTRAL700: '#253858',
    NEUTRAL800: '#172B4D',
    NEUTRAL900: '#091E42',
    BLACK: '#000000',
    PURPLE050: '#EAE6FF',
    PURPLE075: '#C0B6F2',
    PURPLE100: '#998DD9',
    PURPLE200: '#8777D9',
    PURPLE300: '#6554C0',
    PURPLE400: '#5243AA',
    PURPLE500: '#403294',
    RED050: '#FFEBE5',
    RED075: '#FFBDAD',
    RED100: '#FF8F73',
    RED200: '#FF7452',
    RED300: '#FF5630',
    RED400: '#DE350B',
    RED500: '#BF2600',
    YELLOW050: '#FFFAE6',
    YELLOW075: '#FFF0B3',
    YELLOW100: '#FFE380',
    YELLOW200: '#FFC400',
    YELLOW300: '#FFAB00',
    YELLOW400: '#FF991F',
    YELLOW500: '#FF8B00',
  },
  typography: {
    fontMono: '\'SFMono-Medium\', \'SF Mono\', \'Segoe UI Mono\', \'Roboto Mono\', \'Ubuntu Mono\', Menlo, Consolas, Monaco, \'Andale Mono\', monospace',
    fontFamily: 'Roboto',
    useNextVariants: true,
  },
  depth: {
    d100: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 1px 1px 0 rgba(9, 30, 66, 0.25)',
    d200: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25)',
    d300: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 8px 16px -4px rgba(9, 30, 66, 0.25)',
    d400: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 12px 24px -6px rgba(9, 30, 66, 0.25)',
    d500: '0 0 1px 0 rgba(9,30,66,0.31), 0 20px 32px -8px rgba(9,30,66,0.25)',
  },
});

// Make default drop shadow for Paper components less harsh
theme.shadows[2] = '0 1px 3px 0 rgba(0,0,0,0.15)';

export default theme;
