import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  const formPadding = {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(12)
  };

  return {
    footerContainer: {
      width: '100%',
      ...formPadding
    },
    fieldContainer: {
      paddingTop: theme.spacing(2),
    },
    buttonContainer: {
      paddingTop: theme.spacing(2)
    },
    privacyPolicyContainer: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.NEUTRAL100,
      paddingTop: theme.spacing(2),
    },
    tackleLink: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: 'inherit'
    },
    privacyPolicyLink: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: theme.palette.BLUE400
    },
  };
});

export default useStyles;
