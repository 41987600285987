import React, { useContext } from 'react';

import useStyles from './RegistrationForm.layout.styles';

import RegistrationContext from '../../../contexts/RegistrationContext';
import RegistrationError from '../RegistrationError/RegistrationError';
import RegistrationSuccess from '../RegistrationSuccess/RegistrationSuccess';
import RegistrationHeader from '../RegistrationHeader/RegistrationHeader';
import RegistrationBanner from '../RegistrationBanner/RegistrationBanner';
import RegistrationFooter from '../RegistrationFooter/RegistrationFooter';
import AutofillAzure from './RegistrationAutofill/AutofillAzure';
import RegForm from './Form/RegForm';

import { AZURE } from '../../../utils/constants';

const RegistrationFormLayout = ({
  fields,
  registrationData,
  registrationErrors,
  handleChange,
  handleAutofill,
  onSubmit,
  submitButtonText,
  alertError,
  redirectUri,
}) => {
  const {
    state: {
      product,
      vendor,
      registrationSuccess,
    },
  } = useContext(RegistrationContext);

  const formStyles = product.registration?.form || {};
  const classes = useStyles(formStyles);
  
  return (
    <div className={classes.container}>
      <RegistrationHeader />

      {!registrationSuccess && (
        <RegistrationBanner />
      )}

      <div className={classes.formContainer}>
        {alertError && (
          <div className={classes.inputContainer}>
            <RegistrationError
              vendor={vendor}
              product={product}
              alertError={alertError}
            />
          </div>
        )}

        {
          product.cloud === AZURE && !registrationSuccess ? AutofillAzure(handleAutofill) : null
        }

        {registrationSuccess && (
          <RegistrationSuccess
            successBodyMessage={product.registration?.success?.message}
            redirectUri={redirectUri}
          />
        )}

        {!registrationSuccess && (
          <RegForm
            fields={fields}
            registrationData={registrationData}
            registrationErrors={registrationErrors}
            handleChange={handleChange}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
          />
        )}
      </div>

      <RegistrationFooter />
    </div>
  );
};

export default RegistrationFormLayout;
