import { HTTPError } from 'ky';

import { CONTACT_TACKLE, CONTACT_SUPPORT } from './constants';


export class FriendlyHTTPError extends HTTPError {
  constructor(props) {
    super(props);

    const {
      code = 'default_error',
      message = httpFriendlyErrors.default.message,
    } = props;

    this.code = code;
    this.message = message;
    this.ctaButton = httpFriendlyErrors[code]?.ctaButton;
  }
}

// Note: cta subject is appended to cloud + vendorname.
export const httpFriendlyErrors = {
  invalid_entitlements: {
    message: 'Entitlements failed. Re-login to AWS with the payer account and try again.',
  },
  missing_entitlements: {
    message: 'Entitlements missing. Payment method rejected. Update payment and try again.',
  },
  procurement_service_error: {
    message: 'Vendor is misconfigured. Please contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Vendor Misconfigured',
    },
  },
  entitlement_approval_failure: {
    message: 'GCP Entitlement failed approval. Please contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Entitlement Approval',
    },
  },
  account_approval_failure: {
    message: 'GCP Account failed approval. Please contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Account Approval',
    },
  },
  no_account: {
    message: 'Incorrect URL. Please contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Incorrect URL',
    },
  },
  invalid_configuration: {
    message: 'Vendor is misconfigured. Please contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Vendor Misconfigured',
    },
  },
  activation_failure: {
    message: 'Azure activation failed. Please contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Activation Failure',
    },
  },
  default:  {
    message: 'Unable to submit. Please try again. If you continue to experience this contact Tackle support for assistance.',
    ctaButton: {
      buttonText: CONTACT_TACKLE,
      href: CONTACT_SUPPORT,
      subject: 'Buyer Registration Failure',
    },
  },
  azure_autofill_failure: {
    message: 'Error: Unable to connect to Azure. Please complete the fields below.'
  }
};
