import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {

  return {
    ctaButton: {
      backgroundColor: theme.palette.NEUTRAL020,
      contrastText: theme.palette.BLUE400,
      color: theme.palette.BLUE400,
      '&:hover': {
        backgroundColor: theme.palette.NEUTRAL020
      }
    },
    ctaButtonText: {
      color: theme.palette.BLUE400,
    }
  };
});

export default useStyles;
