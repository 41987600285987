import { VALIDATION_TYPE_EMAIL } from './constants';

const is_valid_email = (value) => {
  // allows for any input of the format
  // x@x.x
  // as long as x is not whitespace or '@'
  const match_email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
  return match_email.test(value);
};

export default {
  [VALIDATION_TYPE_EMAIL]: is_valid_email,
};
