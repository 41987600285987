import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  body: {
    paddingTop: theme.spacing(6),
  },
  paragraph: {
    paddingTop: theme.spacing(3),
  }
}));

export default useStyles;
